/**
 * Polyfill stable language features. These imports will be optimized by `@babel/preset-env`.
 *
 * See: https://github.com/zloirock/core-js#babel
 */
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './app/helpers/css-escape';
(window as any).global = window;
global.Buffer = global.Buffer || require('buffer').Buffer;
global.process = global.process || require('process');
(window as any).process = {
  env: { DEBUG: undefined },
};
